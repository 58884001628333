import {
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  sentryDsn,
} from "@src/runtime-config-base";
import { RuntimeConfig } from "@src/runtime-config-types";

export const runtimeConfig: RuntimeConfig = {
  environment: "production",
  appRegion: "eu",
  appEnv: "com-production",
  urls: {
    apiUrl: "https://core.api.holobuilder.com",
    baseUrl: "https://admin.holobuilder.com",
    dashboardUrl: "https://dashboard.holobuilder.com",
    webEditorUrl: "https://app.holobuilder.com/app",
    entryPageUrl: "https://workspace.holobuilder.com",
    adminPanelApi: "https://admin.holobuilder.com/api",
  },
  deployment: {
    topLevelDomain: "intl",
    environment: "production",
  },
  keys: {
    sentryDsn,
  },
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support@holobuilder.com",
  isNumberedEnv: false,
  numberedEnv: null,
};
