import {
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  numberedEnv,
  isNumberedEnv,
  sentryDsn,
} from "@src/runtime-config-base";
import { RuntimeConfig } from "@src/runtime-config-types";

export const runtimeConfig: RuntimeConfig = {
  environment: "staging",
  appRegion: "com",
  appEnv: "com-staging",
  urls: {
    apiUrl: "https://core.api.staging.holobuilder.com",
    baseUrl: "https://admin.staging.holobuilder.com",
    dashboardUrl: "https://dashboard.staging.holobuilder.com",
    webEditorUrl: "https://app.staging.holobuilder.com/app",
    entryPageUrl: "https://workspace.staging.holobuilder.com",
    adminPanelApi: "https://admin.staging.holobuilder.com/api",
  },
  deployment: {
    topLevelDomain: "intl",
    environment: "staging",
  },
  keys: {
    sentryDsn,
  },
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support+staging@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};
