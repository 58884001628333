export function getNumberedEnv(host: string): string | null {
  const numberedEnv = host.match(/^dev\d{1,2}/);
  return numberedEnv?.length ? numberedEnv[0] : null;
}

export function isHostNumberedEnv(host: string): boolean {
  return /^dev\d{1,2}/.test(host);
}

export const appVersion: string = "3.0.83";
export const appTimestamp: string = "2025-04-07T16:31:27.000Z";
export const commitId: string = "b61facc48fb675d516c7f0bdb2761efe9e08ad95";
export const commitUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/AdminPanel/commit/b61facc48fb675d516c7f0bdb2761efe9e08ad95?refName=refs%2Fheads%2Fmaster";
export const pullRequestId: string = "0";
export const pullRequestUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/AdminPanel/pullrequest/0";
export const jiraTicketId: string = "0";
export const jiraTicketUrl: string = "https://faro01.atlassian.net/browse/0";

export const sentryDsn =
  "https://a5f0e653e508490282b7d14d19443b9f@o381590.ingest.sentry.io/5580037";

export const isNumberedEnv = isHostNumberedEnv(window.location.host);
export const numberedEnv = isNumberedEnv
  ? getNumberedEnv(window.location.host)
  : null;
